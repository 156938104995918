import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { Button } from 'theme-ui';
import { Layout, Stack, Main } from '@layout';
import PageTitle from '@components/PageTitle';
import Section from '@components/Section';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';
import { normalizeBlockContentNodes } from '@blocks-helpers';

const Page404 = (props) => {
  const allBlockContent = props.data.allBlockContent;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const page = content['404'];
  return (
    <Layout {...props}>
      <Seo title={page.kicker.text} />
      <Divider />
      <Stack>
        <Main>
          <Section>
            <PageTitle
              header={page.title.text}
              subheader={page.description.text}
            />
          </Section>
          <Section>
            <Button variant='primary' as={Link} to='/'>
              {page.buttons[0].text}
            </Button>
          </Section>
        </Main>
      </Stack>
    </Layout>
  );
};

export const query = graphql`
  query siteErrorPageBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: { in: ["404", "footer", "header"] }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default Page404;
